/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useFilter, usePlot, useDependency } from 'hooks'
import { ControlledForm, AutocompleteSearch, DateTimePicker, LabeledSelect, ExportButton } from 'components'
import { TimeSeriesPlot } from 'components/charts'
import { StylesObject } from "types"
import * as API from 'api'
import { barChartPlotter, formatPickerDateTime } from 'utils'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, FormControl, FormControlLabel, InputLabel } from '@mui/material'
import { InstalledMeter } from 'containers'

const ConsumptionSchedules = () => {

  const [filter, setFilter] = useFilter(null, 'consumptionSchedules')
  const [plotState, plotActions] = usePlot("consumptionSchedules")
  const plot = plotState.plot
  const scope = filter.scope
  const site = filter.site
  const installedMeters = filter.site?.installedMeters || []
  const [dygraphOptions, setDygraphOptions] = useState<any>({ plotter: barChartPlotter })

  const tryGetItem = (key, defaultValue) => {
    const json = localStorage.getItem(key)
    if (!json) { return undefined }
    try {
      return JSON.parse(json)
    } catch {
      return defaultValue
    }
  }

  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0) {
      setFilter({
        from: formatPickerDateTime(moment().startOf('day')),
        to: formatPickerDateTime(moment().endOf('day')),
        priceType: localStorage.getItem('csDash.priceType') || 'T',
        customer: tryGetItem('csDash.customer', undefined),
        site: tryGetItem('csDash.site', undefined),
        installedMeterId: tryGetItem('csDash.installedMeterId', undefined),
      })
    }
  }, [])

  useEffect(() => {
    if (filter?.customer && filter?.site) {
      localStorage.setItem('csDash.customer', JSON.stringify(filter?.customer))
      localStorage.setItem('csDash.site', JSON.stringify(filter?.site))
    }
    if (filter?.installedMeterId) {
      localStorage.setItem('csDash.installedMeterId', JSON.stringify(filter?.installedMeterId))
    }
    localStorage.setItem('csDash.priceType', filter?.priceType)
  }, [filter?.customer, filter?.site, filter?.installedMeterId, filter?.priceType])

  const meterOptions = (filter?.site?.installedMeters || []).reduce((memo, im) => {
    memo[im.installedMeterId] = im.meterSerial
    return memo
  }, {})

  const [loading] = useDependency(async () => {
    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      try {
        await plotActions.plot({ timeSeries })
      } catch (ex) {
        console.error(ex)
      }
    }
  }, [JSON.stringify(filter)])

  const setDay = (offset, start = moment()) => () => {
    const time = start.add(offset, 'days')
    setFilter({
      from: formatPickerDateTime(time.startOf('day')),
      to: formatPickerDateTime(time.endOf('day')),
    })
  }

  const currentTimeSeries = () => {
    return installedMeters.filter(im => `${im.installedMeterId}` === filter?.installedMeterId).flatMap(im => ([{
      series: 'on',
      label: `${im.meterSerial} On`,
      filter: {
        installedMeterId: im.id,
        tradingDatetimeFrom: filter.from,
        tradingDatetimeTo: filter.to,
        priceType: filter.priceType,
      },
      group: filter.group
    }, {
      series: 'off',
      label: `${im.meterSerial} Off`,
      filter: {
        installedMeterId: im.id,
        tradingDatetimeFrom: filter.from,
        tradingDatetimeTo: filter.to,
        priceType: filter.priceType,
      },
      group: filter.group
    }]))
  }

  const onSuggestionsFetchRequestedSites = async (text, callback) => {
    const sites: any = API['Sites']
    const { data } = await sites.index({
      options: {
        fields: { sites: "id,icpNumber", installedMeters: "id,meterSerial" },
        filter: { search: text, customerId: filter.customer?.id },
        page: { number: 1, size: 15 },
        include: 'customer,installedMeters'
      }
    })
    callback(data)
  }

  // keep customer/site relationship valid
  const handleCustomerChange = ({ target: { value } }: any) => {
    setFilter({ ...filter, customer: value, site: value?.id === filter.site?.customer?.id ? filter.site : null })
  }

  const handleSiteChange = ({ target: { value } }: any) => {
    setFilter({ ...filter, site: value, customer: value?.customer || filter.customer })
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}>
        <AutocompleteSearch
          css={styles.leftFilter}
          name='customer'
          searchableField='name'
          onChange={handleCustomerChange}
        />
        <AutocompleteSearch
          css={styles.filter}
          name='site'
          searchableField='icpNumber'
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedSites}
          onChange={handleSiteChange}
          error={scope === 'individual' && !site}
          helperText={scope === 'individual' && !site ? 'is required' : ''}
        />
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.filterRow}>
        <div css={styles.groupOptions}>
          <div css={styles.jumpTo}>
            <Button variant='outlined' onClick={setDay(-1, moment(filter.from))}>⇦</Button>
            <Button variant='outlined' onClick={setDay(0)}>Today</Button>
            <Button variant='outlined' onClick={setDay(-1)}>Yesterday</Button>
            <Button variant='outlined' onClick={setDay(1, moment(filter.from))}>⇨</Button>
          </div>
          <LabeledSelect css={styles.meter} name='installedMeterId' label="Meter" options={meterOptions} />
          <LabeledSelect css={styles.priceType} name='priceType' label="Price Type" options={priceTypes} />
          <ExportButton
            css={styles.exportButton}
            variant='text'
            resource="consumption_schedules/plot"
            params={plotState.params}
            disabled={!plot?.values?.length}
            qsOptions={{ arrayFormat: 'brackets' }}
          />
          <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon /></IconButton>
        </div>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}
      <TimeSeriesPlot
        plot={plot}
        unit="$"
        ylabel="Price ($/MWh)"
        maxGranularity={Dygraph.Granularity.THIRTY_MINUTELY}
        dygraphOptions={dygraphOptions}
        loading={loading}
        colorOverrides={colors}
      />
    </div>
  )
}

const colors = ['#4bad4c', '#AAAAAA']
const priceTypes = {
  'P21+N': 'Prophet + Short',
  'P21': 'Prophet',
  'F': 'Final',
  'T': 'Interim',
  'N': 'Short',
  'L': 'Long'
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
  },
  priceType: {
    minWidth: 180,
    marginRight: 10,
    textAlign: 'left',
  },
  meter: {
    minWidth: 240,
    marginRight: 10,
    textAlign: 'left',
  },
  groupOptions: {
    display: 'flex',
    marginTop: 20,
    width: '100%',
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  },
  jumpTo: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    alignItems: 'center',
    '& .MuiButton-root': {
      marginBottom: 0
    }
  }
} as StylesObject

export default ConsumptionSchedules
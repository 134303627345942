/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource, useAuthorization } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, PageHeader } from 'components'
import { MeterDetail } from "types"
import { decimalAsPercentage, formatLocaleDate, titleize } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [meterDetails, actions] = useResource<MeterDetail>("meterDetails")
  const authorization = useAuthorization()

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'installedMeter' })
    }
  }, [id])

  const meterDetail: Partial<MeterDetail> = meterDetails.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title="Meter Detail" />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 150 }} variant='head'>Actions</TableCell>
            <TableCell>
              {authorization.internalOrAdmin && <Link to={`/meter_details/${meterDetail.id}/edit`}>
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </Link>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Meter Serial</TableCell>
            <TableCell>{String(meterDetail?.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Gatewway Serial</TableCell>
            <TableCell>{String(meterDetail?.installedMeter?.gatewaySerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Start Date</TableCell>
            <TableCell>{meterDetail.startDate ? formatLocaleDate(meterDetail.startDate) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>End Date</TableCell>
            <TableCell>{meterDetail.endDate ? formatLocaleDate(meterDetail.endDate) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Load Type</TableCell>
            <TableCell>{titleize(String(meterDetail.loadType || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Forecast Method</TableCell>
            <TableCell>{titleize(String(meterDetail.forecastMethod || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Persistence Forecast Period Count</TableCell>
            <TableCell>{String(meterDetail.persistenceForecastPeriodCount || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Read Rejection Threshold kW</TableCell>
            <TableCell>{String(meterDetail.readRejectionThresholdkW || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Forecast Accuracy Warning Threshold</TableCell>
            <TableCell>{String(meterDetail.forecastAccuracyWarningThreshold || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Forecast Accuracy Exclusion Threshold</TableCell>
            <TableCell>{String(meterDetail.forecastAccuracyExclusionThreshold || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>FIR Factor</TableCell>
            <TableCell>{String(decimalAsPercentage(meterDetail.firFactor || ''))}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>SIR Factor</TableCell>
            <TableCell>{String(decimalAsPercentage(meterDetail.sirFactor || ''))}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Revenue Split Factor</TableCell>
            <TableCell>{String(decimalAsPercentage(meterDetail.revenueSplitFactor || ''))}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Is Sensitive Load</TableCell>
            <TableCell>{String(meterDetail.isSensitiveLoad ?? '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Requires Manual Rearm</TableCell>
            <TableCell>{String(meterDetail.requiresManualRearm ?? '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Rearm After (minutes)</TableCell>
            <TableCell>{String(meterDetail.rearmAfterMinutes ?? '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Notify Armed Load Below kW</TableCell>
            <TableCell>{String(meterDetail.notifyArmedLoadBelowkW ?? '')}</TableCell>
          </TableRow>
          {
            meterDetail?.loadType === 'genesis_price_controlled' && <>
              <TableRow>
                <TableCell variant='head'>Charge Per Trading Period (kWh)</TableCell>
                <TableCell>{String(meterDetail.chargePerTradingPeriodKwh ?? '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Minimum Charge (kWh)</TableCell>
                <TableCell>{String(meterDetail.minimumChargeKwh ?? '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Target EOD Charge (kWh)</TableCell>
                <TableCell>{String(meterDetail.targetEodChargeKwh ?? '')}</TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show
/** @jsxImportSource @emotion/react */
import React from 'react'
import { PageHeader, Pagination, ErrorBanner, PageContainer, SearchCombo, AutocompleteSearch, DateTimePicker } from 'components'
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDateTime } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { ConsumptionSchedule } from "types"

const List = () => {
  const [consumptionSchedules, actions] = useResource<ConsumptionSchedule>("consumptionSchedules")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("consumptionSchedules")
  const [filter, setFilter] = useFilter(setPage, 'consumptionSchedules')

  const [, , reloadConsumptionSchedules] = useDependency(() => (
    actions.index({
      page: page,
      fields: { consumptionSchedules: 'tradingDatetime,tradingDate,tradingPeriod,consume', installedMeters: 'meterSerial' },
      include: 'installedMeter',
      sort: '-tradingDate,-tradingPeriod,installedMeterId',
      pageSize: 16,
      filter
    })
  ), [page, filter])

  const renderConsumptionScheduleListItem = (consumptionSchedule) => {
    const { id, installedMeter, tradingDatetime, tradingPeriod, consume } = consumptionSchedule
    return (
      <TableRow key={id} sx={{ background: (consume ? '#c6efce' : '#ffc7ce') }}>
        <TableCell>{installedMeter?.meterSerial}</TableCell>
        <TableCell>{formatLocaleDateTime(tradingDatetime)}</TableCell>
        <TableCell>{tradingPeriod}</TableCell>
        <TableCell>{consume ? 'Yes' : 'No'}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Consumption Schedules`} />
      <ErrorBanner>
        {apiErrorStringsFor(consumptionSchedules, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={consumptionSchedules.totalPages} page={page} onPageSelected={setPage} startAdornment={(
        <SearchCombo searchTextMember='meterSerial' onFilterChange={setFilter} filter={filter}>
          <AutocompleteSearch fullWidth name='customer' searchableField="name" />
          <AutocompleteSearch fullWidth name='site' searchableField="icpNumber" />
          <AutocompleteSearch fullWidth subject='meter' name='meterSerial' searchableField="serial" valueAs="string" />
          <DateTimePicker label='Start Time' name='tradingDatetimeFrom' />
          <DateTimePicker label='End Time' name='tradingDatetimeTo' />
        </SearchCombo>
      )} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Meter</TableCell>
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell sx={{ width: '40%' }}>Consume</TableCell>
          </TableRow>
        </TableHead>
        {consumptionSchedules.list.map(renderConsumptionScheduleListItem)}
      </Table>
      <Pagination totalPages={consumptionSchedules.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List
// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    signingToken: endpoint => endpoint.path(() => {
      return '/tokens/signing_token'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Healthchecks = ProtectedJsonAPI.extend(
  '',
  {
    ping: endpoint => endpoint.path(() => {
      return '/healthcheck/ping'
    }),
    show: endpoint => endpoint.path(() => {
      return '/healthcheck'
    }),
  }
)

export const Prices = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/prices/plot'
    }),
    manualRequest: endpoint => endpoint.method('post').path(() => {
      return '/prices/manual_request'
    }),
    index: endpoint => endpoint.path(() => {
      return '/prices'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/prices/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const EnergyPrices = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/energy_prices/plot'
    }),
  }
)

export const MeterReadUploads = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/meter_read_uploads'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/meter_read_uploads'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/meter_read_uploads/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const MeterReads = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/meter_reads/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/meter_reads'
    }),
  }
)

export const MeterTestResultUploads = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/meter_test_result_uploads'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/meter_test_result_uploads'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/meter_test_result_uploads/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/meter_test_result_uploads/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const MeterTestResults = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/meter_test_results/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/meter_test_results'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const MeterDetails = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/meter_details'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/meter_details'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/meter_details/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/meter_details/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/meter_details/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const InstalledMeters = ProtectedJsonAPI.extend(
  '',
  {
    syncStatus: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/installed_meters/${id}/sync_status`
      }
      throw new ApiPathError('sync_status', {id})
    }),
    updateAvailabilities: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/installed_meters/${id}/update_availabilities`
      }
      throw new ApiPathError('update_availabilities', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/installed_meters'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/installed_meters'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/installed_meters/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/installed_meters/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/installed_meters/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Gateways = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/gateways'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/gateways'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/gateways/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/gateways/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/gateways/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Meters = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/meters'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/meters'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/meters/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/meters/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/meters/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const ConsumptionSchedules = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/consumption_schedules/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/consumption_schedules'
    }),
  }
)

export const MeterReadReplays = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/meter_read_replays'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/meter_read_replays'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/meter_read_replays/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/meter_read_replays/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/meter_read_replays/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const TrippedInstalledMeters = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/tripped_installed_meters'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tripped_installed_meters/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const Notifications = ProtectedJsonAPI.extend(
  '',
  {
    mark: endpoint => endpoint.method('put').path(() => {
      return '/notifications/mark'
    }),
    index: endpoint => endpoint.path(() => {
      return '/notifications'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/notifications/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const NotificationTypes = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/notification_types'
    }),
  }
)

export const IotCentralTelemetries = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/iot_central_telemetries'
    }),
  }
)

export const StagingMeterReads = ProtectedJsonAPI.extend(
  '',
  {
    create: endpoint => endpoint.method('post').path(() => {
      return '/staging_meter_reads'
    }),
  }
)

export const ForecastEvaluations = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/forecast_evaluations'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/forecast_evaluations/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const ForecastEvaluationRuns = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/forecast_evaluation_runs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/forecast_evaluation_runs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const Invoices = ProtectedJsonAPI.extend(
  '',
  {
    sendBCTI: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/invoices/${id}/send_bcti`
      }
      throw new ApiPathError('send_bcti', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/invoices'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/invoices/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const InvoiceRuns = ProtectedJsonAPI.extend(
  '',
  {
    override: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/invoice_runs/${id}/override`
      }
      throw new ApiPathError('override', {id})
    }),
    approve: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/invoice_runs/${id}/approve`
      }
      throw new ApiPathError('approve', {id})
    }),
    retrySendBCTI: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/invoice_runs/${id}/retry_send_bcti`
      }
      throw new ApiPathError('retry_send_bcti', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/invoice_runs'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/invoice_runs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/invoice_runs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const Settlements = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/settlements/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/settlements'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/settlements/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const SettlementRuns = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/settlement_runs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/settlement_runs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const BulkOffers = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/bulk_offers'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/bulk_offers/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const BulkOfferBatches = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/bulk_offer_batches'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/bulk_offer_batches/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const BulkReserveSources = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/bulk_reserve_sources'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/bulk_reserve_sources'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/bulk_reserve_sources/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/bulk_reserve_sources/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/bulk_reserve_sources/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const OfferRunLogs = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/offer_run_logs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/offer_run_logs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const OfferedForecasts = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/offered_forecasts'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/offered_forecasts/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const AdhocAvailabilities = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/adhoc_availabilities'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/adhoc_availabilities'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/adhoc_availabilities/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/adhoc_availabilities/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/adhoc_availabilities/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const AvailabilityUploads = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/availability_uploads'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/availability_uploads'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/availability_uploads/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/availability_uploads/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/availability_uploads/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Forecasts = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/forecasts/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/forecasts'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/forecasts/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const UnderFrequencyEventDataEntries = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/under_frequency_event_data_entries/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/under_frequency_event_data_entries'
    }),
  }
)

export const ForecastRuns = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/forecast_runs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/forecast_runs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const ForecastRunLogs = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/forecast_run_logs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/forecast_run_logs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const DispatchInstructions = ProtectedJsonAPI.extend(
  '',
  {
    manualRequest: endpoint => endpoint.method('post').path(() => {
      return '/dispatch_instructions/manual_request'
    }),
    index: endpoint => endpoint.path(() => {
      return '/dispatch_instructions'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/dispatch_instructions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const DerivedDispatches = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/derived_dispatches'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/derived_dispatches/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const ConfigOptions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/config_options'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/config_options'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/config_options/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/config_options/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/config_options/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Sites = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/sites'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/sites'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/sites/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/sites/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/sites/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Customers = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/customers'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/customers'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/customers/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/customers/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/customers/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Offers = ProtectedJsonAPI.extend(
  '',
  {
    latestAcknowledgedTime: endpoint => endpoint.path(() => {
      return '/offers/latest_acknowledged_time'
    }),
    plot: endpoint => endpoint.path(() => {
      return '/offers/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/offers'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/offers/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const OfferRuns = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/offer_runs'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/offer_runs/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const UnderFrequencyEvents = ProtectedJsonAPI.extend(
  '',
  {
    trigger: endpoint => endpoint.method('post').path(() => {
      return '/under_frequency_events/trigger'
    }),
    fileUploaded: endpoint => endpoint.method('post').path(() => {
      return '/under_frequency_events/file_uploaded'
    }),
    index: endpoint => endpoint.path(() => {
      return '/under_frequency_events'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/under_frequency_events/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const HotWaterUploads = ProtectedJsonAPI.extend(
  '',
  {
    confirm: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/hot_water_uploads/${id}/confirm`
      }
      throw new ApiPathError('confirm', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/hot_water_uploads'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/hot_water_uploads'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/hot_water_uploads/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/hot_water_uploads/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const HotWaterEntries = ProtectedJsonAPI.extend(
  '',
  {
    plot: endpoint => endpoint.path(() => {
      return '/hot_water_entries/plot'
    }),
    index: endpoint => endpoint.path(() => {
      return '/hot_water_entries'
    }),
  }
)

export const ServiceAccountSecrets = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({serviceAccountId}) => {
      if (serviceAccountId) {
        return `/service_accounts/${serviceAccountId}/secrets`
      }
      throw new ApiPathError('index', {serviceAccountId})
    }),
    create: endpoint => endpoint.method('post').path(({serviceAccountId}) => {
      if (serviceAccountId) {
        return `/service_accounts/${serviceAccountId}/secrets`
      }
      throw new ApiPathError('create', {serviceAccountId})
    }),
    show: endpoint => endpoint.path(({serviceAccountId, id}) => {
      if (serviceAccountId && id) {
        return `/service_accounts/${serviceAccountId}/secrets/${id}`
      }
      throw new ApiPathError('show', {serviceAccountId, id})
    }),
    update: endpoint => endpoint.method('put').path(({serviceAccountId, id}) => {
      if (serviceAccountId && id) {
        return `/service_accounts/${serviceAccountId}/secrets/${id}`
      }
      throw new ApiPathError('update', {serviceAccountId, id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({serviceAccountId, id}) => {
      if (serviceAccountId && id) {
        return `/service_accounts/${serviceAccountId}/secrets/${id}`
      }
      throw new ApiPathError('destroy', {serviceAccountId, id})
    }),
  }
)

export const ServiceAccounts = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/service_accounts'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/service_accounts'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/service_accounts/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/service_accounts/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/service_accounts/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const OfferSubmissions = ProtectedJsonAPI.extend(
  '',
  {
    results: endpoint => endpoint.method('post').path(() => {
      return '/offer_submissions/results'
    }),
    index: endpoint => endpoint.path(() => {
      return '/offer_submissions'
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
